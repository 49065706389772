import { Box, Alert, AlertIcon, CloseButton } from "@chakra-ui/react";
import { black, darkDarkGray, darkGray, gray } from "./functions/Colors";
import UseGetSize from "./functions/UseGetSize";

import styles from "./css/hexagon.css";
import Header from "./header";
import Body from "./body";
import { useState, useEffect } from "react";

export const Home = () => {
  const { height, width } = UseGetSize();
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    // Check if the device is mobile
    const isMobile = () => window.innerWidth <= 768; // Example breakpoint for mobile
    if (isMobile()) {
      setShowNotification(true); // Show notification for mobile devices
    }
  }, []);

  return (
    <Box w={width} h={height} position="relative" overflow="hidden" bg={black}>
      {showNotification && (
        <Box
          position="fixed"
          top="20%"
          left="10px"
          zIndex={9999}
          w="calc(100% - 20px)"
          maxW="400px"
        >
          <Alert status="info" borderRadius="md" boxShadow="lg">
            <AlertIcon />
	    <Box fontSize="1.25em" lineHeight="1.5">
            Hello fellow Yiffer!<br />
	    It seems that you are watching this page on your phone!<br />
	    Sadly, this page is still under development, and therefore, elements do not work correctly :c<br />
	    You can use landscape mode, so the page is somehow usable tho :3<br />
           </Box> 
	   <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={() => setShowNotification(false)}
            />
          </Alert>
        </Box>
      )}

      <Box
        className="main"
        position="absolute"
        left={-50}
        top={-50}
        w="125%"
        h="125%"
        zIndex={10}
      >
        <Box className="container">
          {Array.from({ length: Math.floor(width / 2) }).map((_, index) => (
            <div key={index} style={{ zIndex: 10, position: "relative" }}></div>
          ))}
        </Box>
      </Box>
      <Box
        w="100%"
        h="100%"
        position="absolute"
        zIndex={10}
        bgGradient={`linear-gradient(${darkDarkGray}, transparent 25%), linear-gradient(to right, ${darkDarkGray}, transparent 25%), linear-gradient(to left, ${darkDarkGray}, transparent 25%), linear-gradient(to top, ${darkDarkGray}, transparent 25%)`}
      ></Box>

      <Header />
      <Body />
    </Box>
  );
};

export default Home;
