import {
  Box,
  Flex,
  Image,
  Spacer,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { darkDarkGray, gray, lightGray, yellow } from "./functions/Colors";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import getImages from "./functions/loadImages";
import UseGetSize from "./functions/UseGetSize";

import "./css/carousel.css";
import { useEffect, useMemo, useRef, useState } from "react";

const handleDragStart = (e) => e.preventDefault();

const GalleryPage = ({ height, width }) => {
  const [imagePaths, setImagePaths] = useState([]);

  const groupRef = useRef(null);

  function getImageAspectRatio(src) {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img"); // Create a new HTML image element

      img.src = src; // Set the source of the image

      img.onload = () => {
        const aspectRatio = img.naturalWidth / img.naturalHeight; // Calculate aspect ratio
        resolve(aspectRatio); // Resolve the promise with the aspect ratio
      };

      img.onerror = () => {
        reject(new Error("Image failed to load")); // Reject the promise on error
      };
    });
  }

  const [items, setItems] = useState([]);
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    const aspectRatioPromises = Object.keys(imagePaths).map((src) => {
      return getImageAspectRatio(imagePaths[src]).then((ratio) => {
        return ratio * (height * 0.9); // Calculate width based on height
      });
    });

    Promise.all(aspectRatioPromises)
      .then((widths) => {
        const totalWidth = widths.reduce((acc, width) => acc + width, 0);
        setMaxWidth(totalWidth); // Set maxWidth once after all calculations
      })
      .catch((error) => {
        console.error(error.message);
      });

    const images = Object.keys(imagePaths).map((src) => (
      <Image
        key={src}
        src={imagePaths[src]}
        role="presentation"
        height={height * 0.9}
        //objectFit="contain"
        position="relative"
        className="card"
      />
    ));

    setItems(images); // Set items after calculating widths
  }, [imagePaths, height]);

  const responsive = {
    0: {
      items: 2,
    },
    1024: {
      items: 3,
      itemsFit: "contain",
    },
  };

  const [speed, setSpeed] = useState(0.64);
  const speedMultipliers = [0.25, 0.5, 1, 2, 4];

  const animationDuration = ((maxWidth / width) * 4) / speed;

  const groupStyle = {
    "--translate-x-end": `-${maxWidth}px`,
    "--translate-x-start": "0%",
    "--animation-duration": `${animationDuration}s`,
  };

  const areImagesLoaded = () => {
    return Promise.all(
      Array.from(items)
        .filter((img) => !img.complete)
        .map(
          (img) =>
            new Promise((resolve) => {
              const img2 = document.createElement("img");
              img2.src = img.src;
              img2.onload = img2.onerror = resolve;
            }),
        ),
    ).then(() => {
      console.log("images finished loading");
    });
  };

  const [imagesLoading, setImagesLoading] = useState(true);

  useEffect(() => {
    areImagesLoaded()
      .then(() => {
        console.log("All images finished loading");
        setImagesLoading(false);
      })
      .catch((error) => {
        console.error("Error loading images:", error);
      });
  }, [imagePaths]);

  const Gallery = () => {
    return !imagesLoading ? (
      <Box w="100%" h="100%" className="carousel">
        <Box className="group" style={groupStyle}>
          {items}
          {items}
        </Box>
      </Box>
    ) : (
      <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
        <Spinner
          boxSize={`${height / 3}px`}
          thickness={`${height / 100}px`}
          color={lightGray}
        />
      </Flex>
    );
  };

  // tabs
  const tabNames = [
    "hidden",
    "flat colors",
    "simple shade",
    "refsheets",
    "animations",
  ];
  const directories = ["dir1", "dir2", "dir3", "dir4"];
  const [selectedTab, setSelectedTab] = useState("hidden");

  const tabInfo = {
    hidden: [
      ["", ""],
      ["", ""],
      ["", ""],
    ],
    "flat colors": [
      ["To Waist", "30$"],
      ["Halfbody", "40$"],
      ["Fullbody", "50$"],
    ],
    "simple shade": [
      ["To Waist", "50$"],
      ["Halfbody", "60$"],
      ["Fullbody", "70$"],
    ],
    refsheets: [
      ["Small", "140$"],
      ["Basic", "180$"],
      ["Big / Artistic ", "220$ / 260$"],
    ],
    animations: [
      ["Simple", "+100% Price"],
      ["Mid", "+200% Price"],
      ["Hard", "+400% Price"],
    ],
  };

  const handleTabChange = (index) => {
    if (index !== 0) {
      setImagesLoading(true);
    }
    setSelectedTab(tabNames[index]); // Update state with the selected tab value
  };

  useEffect(() => {
    if (selectedTab !== "hidden") {
      setImagePaths(getImages(directories[tabNames.indexOf(selectedTab) - 1]));
    } else {
      setImagePaths([]);
    }
  }, [selectedTab]);

  const gall = useMemo(
    () => <Gallery />,
    [imagesLoading, imagePaths, groupStyle],
  );

  return (
    <>
      <Box h={height} w="100%" position="relative">
        <Box position="relative" h="10%" w="100%">
          <Box h="100%" w="80%" left={(width / 10) * 1.5} position="relative">
            <Tabs
              isFitted
              variant="unstyled"
              color={lightGray}
              size={`${height}px`}
              index={tabNames.indexOf(selectedTab)}
              onChange={handleTabChange}
            >
              <TabList>
                {tabNames.map((tab) => (
                  <Tab
                    _selected={{ textColor: yellow }}
                    _hover={{ bgColor: gray }}
                    fontSize={`${height / 20}`}
                    fontFamily="LonelyCake"
                    textAlign="center"
                  >
                    {tab.toUpperCase()}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          </Box>
        </Box>
        <Box
          position="relative"
          h="90%"
          w="100%"
          //left={(width / 10) * 1.5}
          overflow="hidden"
        >
          {selectedTab !== "hidden" ? <Box h="100%">{gall}</Box> : <></>}
        </Box>
        {/*
        <Box position="absolute" h="90%" w="10%" right={0} top={height / 10}>
          <Flex h="100%" direction="column" alignItems="center">
            <Spacer />
            <Image
              src="./icons/home.png"
              _hover={{ transform: "scale(1.1)" }}
              transition="transform 0.4s"
              w="80%"
              onClick={() =>
                setSpeed(
                  speedMultipliers[
                    Math.min(
                      speedMultipliers.indexOf(speed) + 1,
                      speedMultipliers.length - 1,
                    )
                  ],
                )
              }
            />
            <Spacer />
            <Image
              src="./icons/e61.png"
              _hover={{ transform: "scale(1.1)" }}
              transition="transform 0.4s"
              w="80%"
              onClick={() =>
                setSpeed(
                  speedMultipliers[
                    Math.max(speedMultipliers.indexOf(speed) - 1, 0)
                  ],
                )
              }
            />
            <Spacer />
          </Flex>
        </Box>
        */}
      </Box>
      <Box
        left={width / 5}
        height={height / 6}
        w="60%"
        position="relative"
        fontFamily="LonelyCake"
        //bgColor="red"
      >
        <Flex
          justify="space-between"
          align="center"
          height="100%"
          fontSize={`${height / 17.5}`}
          textColor={lightGray}
        >
          <Box textAlign="center">
            <Text>
              {tabInfo[selectedTab][0][0]}
              <br />
              {tabInfo[selectedTab][0][1]}
            </Text>
          </Box>
          <Box textAlign="center">
            <Text>
              {tabInfo[selectedTab][1][0]}
              <br />
              {tabInfo[selectedTab][1][1]}
            </Text>
          </Box>
          <Box textAlign="center">
            <Text>
              {tabInfo[selectedTab][2][0]}
              <br />
              {tabInfo[selectedTab][2][1]}
            </Text>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default GalleryPage;
