import { Box, Flex, Image, Spacer, Text } from "@chakra-ui/react";
import UseGetSize from "./functions/UseGetSize";
import { darkDarkGray, darkGray, lightGray, yellow } from "./functions/Colors";
import useTopHeight from "./functions/UseTopHeight";
import { useEffect, useMemo, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import font from "./css/font.css";
import GalleryPage from "./gallery";
import CalculatorPage from "./calculator";
import TOSPage from "./tos";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SideIcons = ({ icon, page, setPage, h, setPoint, point }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isSelected, setIsSelected] = useState(true);

  useEffect(() => {
    if (icon === page) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [page]);

  return (
    <>
      <Box
        position="relative"
        transition="margin 0.4s"
        //marginY={isHovered || isSelected ? "10px" : "0"}
        zIndex={15}
      >
        <Image
          src={`./icons/${icon}.png`}
          boxSize={`${h / 5}`}
          border={`${isSelected ? h / 50 : h / 200}px solid white`}
          borderRadius="50%"
          transition="transform 0.4s ease, border 0.4s ease"
          transform={
            (isHovered || isSelected) && (point === "-1" || point === icon)
              ? "scale(1.3)"
              : "scale(1)"
          }
          onMouseEnter={() => {
            setIsHovered(true);
            setPoint(`${icon}`);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
            setPoint("-1");
          }}
          onClick={() => setPage(icon)}
        />
      </Box>
    </>
  );
};

const HomePage = ({ height, width }) => {
  const bigFontSize = height / 18;
  const fontSize = height / 22;

  return (
    <>
      <Image
        src="./icons/text2.png"
        height={height / 3}
        position="absolute"
        left={-width / 50}
        top={-height / 20}
      />
      <Flex
        w="100%"
        h="100%"
        direction="column"
        alignItems="center"
        position="relative"
      >
        <Image
          height={height / 2.25}
          src="./icons/PFP.png"
          border="12px solid black"
          borderRadius="50%"
          zIndex={15}
        />
        <Box
          h="60%"
          w="100%"
          marginY={-height / 12.5}
          rounded={height / 10}
          bgColor="rgba(0, 0, 0, 0.5)"
        >
          <Flex w="100%" h="100%" direction="column" alignItems="center">
            <Box h="17.5%" w="100%" />
            <Box
              h="25%"
              w="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Text
                fontFamily="LonelyCake"
                color="white"
                fontSize={bigFontSize * 2}
              >
                TenKelly's Art
              </Text>
            </Box>
            <Box
              h="25%"
              w="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                w="80%"
                h="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Text
                  fontFamily="LonelyCake"
                  color={lightGray}
                  fontSize={fontSize}
                  noOfLines={[1, 2]}
                  lineHeight={`${fontSize}px`}
                  textAlign="center"
                >
                  Hewu! My name is Alex, but you can call me Kelly, TenKelly! :3
                </Text>
              </Box>
            </Box>
            <Box h="4%"></Box>
            <Box
              h="25%"
              w="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                w="80%"
                h="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Text
                  fontFamily="LonelyCake"
                  color={lightGray}
                  fontSize={fontSize}
                  noOfLines={[1, 2]}
                  lineHeight={`${fontSize}px`}
                  textAlign="center"
                >
                  I specialise at NSFW Furry/Yiff artworks and animations c:
                </Text>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Image
        src="./icons/text1.png"
        height={height / 3}
        position="absolute"
        right={-width / 50}
        top={-height / 20}
      />
    </>
  );
};

export const Body = () => {
  const { height, width } = UseGetSize();

  const head_height = height / 20;
  const totalHeight = head_height * 3.5;

  // current page
  const [currentPoint, setCurrentPoint] = useState("-1");
  const [currentPage, setCurrentPage] = useState("home");

  const [galleryHeight, setGalleryHeight] = useState(100);
  const [calculatorHeight, setCalculatorHeight] = useState(100);
  const [tosHeight, setTosHeight] = useState(100);

  const topHeight = useTopHeight();

  const icons = ["home", "gallery", "calculator", "tos"];

  useEffect(() => {
    if (currentPage === "gallery") {
      setGalleryHeight((height - totalHeight) * 0.8);
    }

    if (currentPage === "calculator") {
      setCalculatorHeight((height - totalHeight) * 0.95);
    }

    if (currentPage === "tos") {
      setTosHeight((height - totalHeight) * 0.95);
    }
  }, [currentPage, height]);

  const gall = useMemo(
    () => <GalleryPage height={galleryHeight} width={width} />,
    [galleryHeight, width],
  );

  const call = useMemo(
    () => <CalculatorPage height={calculatorHeight} width={width * 0.8} />,
    [calculatorHeight, width],
  );

  const tos = useMemo(
    () => <TOSPage height={tosHeight} width={width * 0.8} />,
    [tosHeight, width],
  );

  return (
    <>
      <Box
        top={totalHeight}
        h={height - totalHeight}
        w={width}
        position="absolute"
        zIndex={15}
      >
        <Flex w="100%" h="100%" flex direction="row" alignItems="center">
          <Box
            marginLeft={
              currentPage === "home"
                ? Math.round((topHeight - 40) / 3)
                : currentPage === "calculator"
                  ? Math.round((topHeight - 40) / 3) / 8
                  : Math.round((topHeight - 40) / 3) / 2
            }
            marginRight={Math.round(topHeight / 10)}
            w="10%"
            h="80%"
            position="relative"
            transition="margin-top 0.4s ease, margin-left 0.4s ease"
            marginTop={
              currentPage === "home"
                ? 0
                : currentPage === "calculator"
                  ? (height * 0.8) / 15
                  : (height * 0.8) / 5
            }
          >
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              h="100%"
              gap={(height - totalHeight) / 35}
            >
              {icons.map((icon) => (
                <SideIcons
                  icon={icon}
                  page={currentPage}
                  setPage={setCurrentPage}
                  h={(height - totalHeight) * 0.8}
                  point={currentPoint}
                  setPoint={setCurrentPoint}
                />
              ))}
            </Flex>
          </Box>
        </Flex>
        {currentPage === "home" && (
          <Box
            w="66%"
            h="90%"
            position="absolute"
            left="17%"
            top={(height - topHeight) / 50}
          >
            <HomePage height={height - totalHeight} width={width} />
          </Box>
        )}
        {currentPage === "gallery" && (
          <Box
            w="100%"
            h="80%"
            position="absolute"
            top={(height - topHeight) * 0.04}
            zIndex={11}
            bg={darkDarkGray}
          >
            {gall}
          </Box>
        )}
        {currentPage === "calculator" && (
          <Box
            w="80%"
            h="95%"
            position="absolute"
            top={0}
            left={width / 10}
            zIndex={11}
            bgColor="rgba(0, 0, 0, 0.5)"
            rounded={height / 50}
          >
            {call}
          </Box>
        )}
        {currentPage === "tos" && (
          <Box
            w="80%"
            h="95%"
            position="absolute"
            top={0}
            left={width / 10}
            zIndex={11}
            bgColor="rgba(0, 0, 0, 0.5)"
            rounded={height / 50}
          >
            {tos}
          </Box>
        )}
      </Box>
    </>
  );
};

export default Body;
